let host = '';

host = process.env.REACT_APP_BACKEND_URL_CSHARP;

function send(path, method, body) {
  var settings = addSettings(method, body);
  //console.log(host + path);

  var response = fetch(host + path, settings)
    .then((data) => {
      if (data.status === 401) {
        localStorage.removeItem('token');
      } else {
        return data.json();
      }
    })
    .then((response) => {
      return response;
    })
    .catch((error) => console.log(error));
  return response;
}

async function sendWithCallback(path, method, body, success, error) {
  console.log(host + path);
  var settings = addSettings(method, body);
  fetch(host + path, settings)
    .then((data) => {
      //console.log(data);
      if (data.status == 401) {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
      }

      if(data.status != 200){
        throw data;
      }

      try {
        return data.json();
      } catch (error) {
        if (error == null) {
          return;
        }
        //console.log('data.json() error: ' + error);
        //console.log(error);
        return null;
      }
    })
    .then((res) => {
      success(res);
    })
    .catch((err) => {
      if (error == null) {
        return;
      }
      //console.log(err?.message);
      error(err);
    });
}

function addSettings(method, body) {
  let token = null;
  token = localStorage.getItem('token');

  //console.log('addSettings token: ' + token);
  var settings = {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      'credentials': 'include'
    },
  };

  // if (token != null && token != '') {
  //   settings.headers.Authorization = `Bearer ${token}`;
  // }

  if (body != null) {
    settings.body = JSON.stringify(body);
  }

  return settings;
}

export function getList() {
  return fetch('offers').then((data) => data.json());
}

export function headerGet() {
  return send('offerRewards', 'GET');
}

export function getOffers(success, error) {
  var response = sendWithCallback('offers', 'GET', null, success, error);
}

export function getOfferRewards(offerId, success, error) {
  if(offerId != null && offerId > 0){
    return sendWithCallback('OfferRewards/' + offerId + '/offer', 'GET', null, success, error);
  }
  return sendWithCallback('OfferRewards/', 'GET', null, success, error);
}

export function getOfferRewardWithPagination(pageNumber, pageSize, success, error) {
  return sendWithCallback(`OfferRewards?pageNumber=${pageNumber}&pageSize=${pageSize}`, 'GET', null, success, error);
}

export function getFeaturedOfferRewards(pageNumber, pageSize, success, error) {
  return sendWithCallback(`OfferRewards/Featured?pageNumber=${pageNumber}&pageSize=${pageSize}`, 'GET', null, success, error);
}

export function getClicks() {
  return send('OfferRewards', 'GET');
}

export function headerGet2() {
  const token = null;
  token = localStorage.getItem('token');
  //console.log(token);
}

export function getBalance() {
  return send('offers/balance', 'GET');
}

export function getConversions(success, error) {
  sendWithCallback('offers/conversions', 'GET', null, success, error);
}

export function getCart(success, error) {
  return sendWithCallback('cart', 'GET', null, success, error);
}

export function addToCart(offerId, success, error) {
  sendWithCallback('cart/add/' + offerId, 'POST', null, success, error);
}

export function removeFromCart(offerId, success, error) {
  sendWithCallback('cart/remove/' + offerId, 'POST', null, success, error);
}

export function getTrackingLink(offerId) {
  return send('offers/' + offerId + '/getlink/tracking', 'GET');
}

export function getOfferTrackingLink(offerId, success, error) {
  sendWithCallback('offers/' + offerId + '/getlink/tracking', 'GET', null, success, error);
}

export function redeem(data, success, error) {
  sendWithCallback('offers/redeem', 'POST', data, success, error);
}

export function getRedeemed(success, error){
  return sendWithCallback('offers/redeemed', 'GET', null, success, error);
}

export function getPayouts(success, error) {
  return sendWithCallback('offers/payout', 'GET', null, success, error);
}

export function getGiftCards(success, error) {
  return sendWithCallback('giftcards', 'GET', null, success, error);
}

export function getCategories(success, error){
  return sendWithCallback('offers/categories', 'GET', null, success, error);
}

export function getMinimum(success, error) {
  return sendWithCallback('offers/minimum', 'GET', null, success, error);
}