import React, { Fragment, useEffect } from "react";
import {
  Features,
  Services,
  Team,
  Services2,
  Contacts,
  Reviews,
  ParallaxHeader,
  Process,
} from "../../widgets";
import { useCustomState } from "../../../state/state";
import HowToChild from "./HowToChild";


const MyComponent = () => {
    return <div>Hello, World!</div>;
  };
  

export default ({ pageTitle="pageTitle" }) => {
  const state = useCustomState()[0];

  // for use by page's title
  useEffect(() => {
    document.title = pageTitle;
  }, [pageTitle]);

  return (
    <Fragment>
          {/* <ParallaxHeader /> */}

          <HowToChild />
          {/* <HeaderLanderDyn /> */}
          
          {/* <MyComponent /> */}
          {/* <Team data={state.data.offers} /> */}
          {/* <Services2 data={state.data.services} /> */}
          {/* <Services data={state.data.services} /> */}
          {/* <Features data={state.data.features} /> */}


          {/* <Process data={state.data.process} /> */}
          {/* <Reviews data={state.data.reviews} /> */}
          {/* <Contacts /> */}
    </Fragment>
  );
};
