import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./OfferWallSearch.module.css";

export default (({query, setQuery, setCategory}) => {
  const [searchValue, setSearchValue] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    setQuery(searchValue);
    setCategory(null);
  }, [searchValue]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (searchValue === "") return;
    setSearchValue("");
    setQuery("");
    //navigate("/OfferWall/search/" + searchValue);
  };
  return (
    <form onSubmit={handleSubmit} className={styles.search}>
      <input
        type="text"
        placeholder="Search..."
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
      />

      <button>
        <i className="las la-search" />
      </button>
    </form>
  );
});
