import React from 'react';
import SupportForm from '../../../ui/Forms/SupportForm';

const Support = () => {
  return (
    <>
      <SupportForm />
    </>
  )
}

export default Support