function send(path, method, body, success, error){
    //const url = 'https://api.upmatic.io/Authenticate/' + path;
    //const url = 'https://localhost:7229/authenticate/' + path;

    let url = '';

    // if (process.env.NODE_ENV == 'production') {
    //   url = 'https://api.upmatic.io/Authenticate/' + path;
    // } else {
    //   url = 'https://leadid-offerreward-api-stage.azurewebsites.net/Authenticate/' + path;
    // }

    url = process.env.REACT_APP_BACKEND_URL_CSHARP + "authenticate/" + path;

    var settings = {
        method: method,
        headers: {
            'Content-Type': 'application/json'
        }
    }

    if(body != null){
        settings.body = JSON.stringify(body);
    }
    
    fetch(url, settings)
    .then(data => {
        if(data.status === 401){
            localStorage.removeItem('token');
            localStorage.removeItem('user');
        }
        return data.json();
    })
    .then(res => {
        if(res.status === 200 || res.status === 'Success'){
            if(success != null){
                success(res);
            }
        }else{
            if(error != null){
                if(res?.errors != null){
                    error(res.errors);
                }else{
                    error(res);
                }
                
            }
        }
    })
    .catch(res => {
        if(error != null){
            error(res);
        }
    });
}

export function register(registerData, success, error){
    return send('register', 'POST', registerData, success, error);
}

export function login(email, password, success, error){
    return send('login', 'POST', {email: email, password: password}, success, error);
}

export function forgotPassword(email, success, error){
    var data = {
        email: email,
        resetPasswordUrl: window.location.origin + '/ResetPassword'
    }

    return send('forgotPassword', 'POST', data, success, error);
}

export function confirmEmail(data, success, error){
    send('confirmEmail', 'POST', data, success, error);
}

export function resetPassword(resetData, success, error){
    send('resetPassword', 'POST', resetData, success, error);
}

export function logout(success, error){
    return send('logout', 'GET', null, success, error);
}