import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  Fragment,
} from 'react';
import { Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import {
  Header
} from '../../widgets';
import { useCustomState } from '../../../state/state';
import { Container, Table, Button } from 'react-bootstrap';
import '../../../App.css';
import {
  getCart,
  getConversions,
  addToCart,
  removeFromCart,
  redeem,
  getRedeemed,
  getMinimum
} from '../../../services/basicGet';
import styles from '../OfferWall/OfferWall.module.css';
import Login from '../../Login/Login';
import useToken from '../../../useToken';
import LoginSimple from '../../Login/LoginSimple';
import RedeemModal from './RedeemModal';
import CartOfferCard from './CartOfferCard/CartOfferCard';
import './Cart.css';

function getTotal(items) {
  let total = currencyFormat(
    items?.reduce(function (a, item) {
      return a + item.price;
    }, 0)
  );
  return total;// * 100;
}

function currencyFormat(num) {
  return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export default function ({ cart, setCart, pageTitle }) {
  const [state, actions] = useCustomState();

  const { token, setToken } = useToken();
  const [list, setList] = useState();
  //const state = useCustomState()[0];
  const mounted = useRef(true);
  const [isLoading, setIsLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [cartTotal, setCartTotal] = useState(0);
  const [availableTotal, setAvailableTotal] = useState(0);
  const [redeemedTotal, setRedeemedTotal] = useState(0);
  const [displayedOffers, setDisplayedOffers] = useState('earned');
  const [offers, setOffers] = useState({ cart: [], available: [] });
  const [walletAddress, setWalletAddress] = useState();
  const [email, setEmail] = useState();
  const [redeemType, setRedeemType] = useState('crypto');
  const [errorMessage, setErrorMessage] = useState();
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedTab, setSelectedTab] = useState('earned');
  const [redeemed, setRedeemed] = useState();
  const [minimumTotal, setMinimumTotal] = useState(500);

  useEffect(() => {
    if (state.user == null) {
      console.log('in cart useEffect');
      actions.toogleModal();
      return;
    }
    actions.toogleLoading(true);
    getConversions(
      function (conversionsResponse) {
        getCart(
          function (cartResponse) {
            var cartOffersUpdate = [];
            var availableOffersUpdate = [];

            conversionsResponse.map((element) => {
              var newData = {
                id: element.id,
                name: element.title,
                description: element.rewardDescription,
                creator: 'Detail',
                price: element.amount,
                image: element.creativeImage,
                url: element.trackingLink,
                status: element.status,
                bids: [],
              };

              if (cartResponse.some((cartItem) => cartItem.id == element.id)) {
                cartOffersUpdate.push(newData);
              } else {
                availableOffersUpdate.push(newData);
              }
            });

            setOffers({
              cart: cartOffersUpdate,
              available: availableOffersUpdate,
            });
            setCartTotal(getTotal(cartOffersUpdate));
            setAvailableTotal(getTotal(availableOffersUpdate));
            if(availableOffersUpdate == null || availableOffersUpdate.length == 0){
              setSelectedTab('cart');
              setTotal(getTotal(cartOffersUpdate));
            }else{
              setTotal(getTotal(availableOffersUpdate));
            }
            actions.toogleLoading(false);
          },
          function (error) {
            actions.toogleLoading(false);
          }
        );
      },
      function (error) {
        actions.toogleLoading(false);
      }
    );

    getMinimum(function(response){
      if(response != null && response > 0){
        setMinimumTotal(response);
      }
    });

  }, [token]);

  // for page's title element
  useEffect(() => {
    document.title = pageTitle;
  }, [pageTitle]);

  useEffect(() => {
    getRedeemed((redeemedResponse) => {
      if(redeemedResponse == null){
        return null;
      }
      
      let newRedeemed = [];
      redeemedResponse.map((element) => {
        var newData = {
          id: element.id,
          name: element.title,
          description: element.rewardDescription,
          creator: 'Detail',
          price: element.amount,
          image: element.creativeImage,
          url: element.trackingLink,
          status: element.status,
          bids: [],
        };

        newRedeemed.push(newData);
      });

      setRedeemed(newRedeemed);
      setRedeemedTotal(getTotal(newRedeemed));
    });
  }, []);

  const handleAdd = (id) => {
    var tempOfferToAdd = offers.available.find((item) => item.id == id);
    if (tempOfferToAdd) {
      actions.toogleLoading(true);
      addToCart(
        id,
        function () {
          var tempCartOffers = offers.cart;
          tempCartOffers.push(tempOfferToAdd);
          var tempAvailableOffers = offers.available.filter(
            (item) => item.id != id
          );
          setOffers({
            cart: tempCartOffers,
            available: tempAvailableOffers,
          });
          setCartTotal(getTotal(tempCartOffers));
          setAvailableTotal(getTotal(tempAvailableOffers));
          
          if(tempAvailableOffers.length == 0){
            setTotal(getTotal(tempCartOffers));
            setSelectedTab('cart');
          }else{
            setTotal(getTotal(tempAvailableOffers));
          }
          actions.toogleLoading(false);
        },
        function (err) {
          actions.toogleLoading(false);
        }
      );
    }
  };

  const handleDelete = (id) => {
    var tempOfferToDelete = offers.cart.find((item) => item.id == id);
    if (tempOfferToDelete) {
      actions.toogleLoading(true);
      removeFromCart(
        id,
        function () {
          var tempAvailableOffers = offers.available;
          tempAvailableOffers.push(tempOfferToDelete);
          var tempCartOffers = offers.cart.filter((item) => item.id != id);
          setOffers({
            cart: tempCartOffers,
            available: tempAvailableOffers,
          });
          setCartTotal(getTotal(tempCartOffers));
          setAvailableTotal(getTotal(tempAvailableOffers));
          setTotal(getTotal(tempCartOffers));
          actions.toogleLoading(false);
        },
        function (err) {
          actions.toogleLoading(false);
        }
      );
    }
  };
 

  if (!state.user) {
    //return <Login setToken={setToken} />;
    return null;
  }

  if (offers != null) {
    return (
      <>
        {/* <Header>Redeem</Header> */}
        <RedeemModal
          offers={offers}
          setOffers={setOffers}
          minimumTotal={minimumTotal}
          cartTotal={cartTotal}
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
        />

        <Fragment>
          {/* <Header img={state.data.header_bgs.blog}>{pageTitle}</Header> */}
          <section
            className={[
              styles.wrapper,
              styles.with_sidebar + ' ' + styles.right,
            ].join(' ')}
          >
            <aside className={styles.sidebar}>
              <div style={{ assignSelf: 'flex-end', marginTop: 'auto' }}>
                {/* <LoginSimple setToken={setToken} /> */}
                <h1>Total {selectedTab} rewards: {total}</h1>
                {
                  selectedTab == 'cart' && 
                  <>
                    <p>(Minimum {minimumTotal} to redeem)</p>
                    <Button
                      onClick={() => {
                        setModalVisible(!modalVisible);
                      }}
                    >
                      Checkout
                    </Button>
                  </>
                }
          
                {/* {cartTotal >= minimumTotal && <Button>Redeem</Button>}
                {cartTotal < minimumTotal && <Button disabled>Redeem</Button>} */}
              </div>
            </aside>

            <article className="cart">

            <Nav variant="tabs" defaultActiveKey="/home">
              <Nav.Item>
                <Nav.Link onClick={() => {setSelectedTab('earned'); setTotal(availableTotal);}} className={selectedTab == 'earned' ? "active" : ""} title="Completed offers you can add to your cart">Earned</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link onClick={() => {setSelectedTab('cart'); setTotal(cartTotal);}} className={selectedTab == 'cart' ? "active" : ""} title="Check what's in your cart">Cart</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link onClick={() => {setSelectedTab('redeemed'); setTotal(redeemedTotal);}} className={selectedTab == 'redeemed' ? "active" : ""} title="See the offers you redeemed">Redeemed</Nav.Link>
              </Nav.Item>
              {/* <Nav.Item>
                <Nav.Link eventKey="disabled" disabled>
                  Disabled
                </Nav.Link>
              </Nav.Item> */}
            </Nav>
              
              {
                selectedTab == 'earned' &&
                <div className="cards" style={{ textAlign: 'center' }}>
                  <Container>
                    {/* <h1>Available</h1> */}
                    <ul>
                      {offers.available.map((item, index) => (
                        <li key={index}><CartOfferCard action={"add"} offer={item} handleEvent={handleAdd}/></li>
                        )
                      )}
                    </ul>
                  
                  </Container>
                </div>
              }

              {
                selectedTab == 'cart' && 
                <div className="cards" style={{ textAlign: 'center' }}>
                  <Container>
                    {/* <h1>Cart</h1> */}
                    <ul>
                      {offers.cart.map((item, index) => (
                        <li key={index}><CartOfferCard action={"delete"} offer={item} handleEvent={handleDelete}/></li>
                        )
                      )}
                    </ul>
                  </Container>
                </div>
              }

              {
                selectedTab == 'redeemed' &&
                <div className="cards" style={{ textAlign: 'center' }}>
                  <Container>
                    {/* <h1>Available</h1> */}
                    <ul>
                      {redeemed?.map((item, index) => (
                        <li key={index}><CartOfferCard offer={item}/></li>
                        )
                      )}
                    </ul>
                  
                  </Container>
                </div>
              }

            
            </article>
          </section>
        </Fragment>
      </>
    );
  } else {
    return (
      <>
        <h1>No Conversions</h1>
      </>
    );
  }
}
