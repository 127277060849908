import React, { useState, useEffect, Fragment } from "react";
import styles from "./Header.module.css";
import Layout from "../Layout/Layout";
import useToken from '../../../useToken';
import LoggedInUser from '../../Login/LoggedInUser';
import LoggedOutUser from '../../Login/LoggedOutUser';
import { getCart} from '../../../services/basicGet';
import { Button, Link, Logo } from "../../elements";
import THEME from "../../../state/theme";
import { useCustomState } from "../../../state/state";
import { useNavigate } from "react-router-dom";

export default ({ data = [], cart, setCart }) => {
  const {token, setToken} = useToken();
  const [sticky, setSticky] = useState(false);
  const [state, actions] = useCustomState();
  const navigate = useNavigate();
  // 
  let tempKey = data.length;
   //console.log(tempKey);
  // for cart
  const [button, setButton] = useState(true);

  const handleResize = () => {
    setSticky(window.pageYOffset > 200 ? true : false);
  };

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };  

  useEffect(() => {
    window.addEventListener("scroll", handleResize);
    return () => {
      window.removeEventListener("scroll", handleResize);
    };
  }, []);

  useEffect(() => {
    if(state.user){
      //console.log("in Header in useEffect to getCart and showButton");
      getCart().then(res => {
        if(res != null && res.length > 0){
          setCart(res);
        }
      });
    }
   
    showButton();
  }, [state.user]);

  window.addEventListener('resize', showButton);

  const menu = data.map((item, index) => {
    //console.log(index);
    //console.log(item);
    //console.log(item.children);
    //console.log(index);
    if (!item.children) {
      return (
          <li key={'menu_' + index}>
            { item.name.toLowerCase().includes("rewards") && state.user != null && <Link id={item.id} url={item.url} hoverStyle={{ color: THEME.purple }}>
              {item.name}
              {/* <span> { cart.length } </span> */}
            </Link> }
            { item.name.toLowerCase().includes("rewards") && state.user == null && <Link id={item.id} click={() => {
              actions.loginSuccessCallback(function(){
                window.location = '/cart';
              }, function(){
                actions.toogleModal(false);
              });
              actions.toogleModal(true);
            }} hoverStyle={{ color: THEME.purple }}>
              {item.name}
              {/* <span> { cart.length } </span> */}
            </Link> }
            { !item.name.toLowerCase().includes("rewards") && <Link url={item.url} hoverStyle={{ color: THEME.purple }}>
              {item.name}
            </Link> }
          </li>
      );
    } else {
      return (
        <li key={'menu_' + index}>
            <Link url={item.url} hoverStyle={{ color: THEME.color }}>
            <span>
              {item.name}
              </span>
            </Link>
          <ul>
            {item.children.map((subitem, subindex) => (
              <li key={'menu_child_' + subindex}>
                <a href={subitem.url}>{subitem.name} {subindex} </a>
              </li>
            ))}
          </ul>
        </li>
      );
    }
  });

  const header = (
    <Layout>
      <div className={styles.header}>
        <Link url="/" extraClassName={styles.home}>
          <Logo key="1000" light />
        </Link>

      <ul className={styles.menu}>
          <li>
          <Link hoverStyle={{ color: THEME.purple }} extraClassName={styles.user}>
            {state.user}
          </Link>
          </li>
        </ul>
        
        <ul className={styles.menu}>
          {menu}
      
          <li key={'menu_' + tempKey} className={styles.loginlogout}>
          { state.user ? (<LoggedInUser key={'menu_' + tempKey} />) : (<LoggedOutUser key={'menu_' + tempKey} />)}
          </li>
          </ul>
        {/* <ul>
            <li className={styles.menu}>About</li>
            <li className={styles.menu}>FAQ</li>
        </ul> */}

        {/* <div className={styles.btn_desktop}>
          <Button
            after="&#xf107;"
            type="outline-white-tw"
            hoverType="solid-white-tb"
            click={() => actions.toogleModal()}
          >
            Get Quote
          </Button>
        </div> */}
        {/* <div className={styles.btn_desktop}>
          <Button
            after="&#xf107;"
            type="outline-white-tw"
            hoverType="solid-white-tb"
            click={() => actions.toogleModal()}
          >
            Login
          </Button>
        </div> */}

        <div
          className={styles.btn_mobile}
          onClick={() => actions.toogleSidebar()}
        >
          <Button
            after="&#xf0c9;"
            type="solid-white-tb"
            hoverType="solid-gray-tb"
          />
        </div>
                
      </div>
    </Layout>
  );

  return (
    <Fragment>
      <div className={styles.wrapper}>
        {header}
        <div style={{height:'2px', background:'#FFB703', margin: '0', padding: '0' }}></div>   
      </div>
      
      {/* The following is the sticky version of the Navmenu AKA header */}
      <div
        className={[
          styles.wrapper,
          sticky ? styles.sticky : styles.hidden,
        ].join(" ")}
        style={{zIndex:'1000'}}
      >
        {header}
        <div style={{height:'2px', background:'#FFB703', margin: '0', padding: '0' }}></div>   
      </div>
      {/* <div style={{ paddingBottom: 100}}>

      </div> */}
    </Fragment>
  );
};
