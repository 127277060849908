import React from "react";
import styles from "./OfferWallCard.module.css";
import { Link } from "react-router-dom";
import { CardImage } from "../../../../ui";
import { Button } from "../../../../elements";
import { useCustomState } from '../../../../../state/state'

export default ({ offer, category = "", layout = "grid" , token, setOfferDetails}) => {
  const [state, actions] = useCustomState();

  return (
      <a onClick={() => {
        setOfferDetails(offer);
      }} style={{boxShadow: '1px 1px 10px rgb(0, 0, 0, .5)'}}>
    <CardImage image={offer.creativeImage} label={category} layout={layout} data={offer.amount}>
      <div className={styles.card}>
      {
             state.loggedIn && (offer.status == null || offer.status == 'none') && 
            <span className={styles.dot} style={{backgroundColor: 'white'}} title="Available"></span>
          }
          {
            state.loggedIn && (offer.status == 'clicked' || offer.status == 'pending') && 
            <span className={styles.dot} style={{backgroundColor: 'yellow'}} title="Started"></span>
          }
          {
            state.loggedIn && (offer.status == 'converted' || offer.status == 'redeemed') && 
            <span className={styles.dot} style={{backgroundColor: 'lawngreen'}} title="Completed"></span>
          }
      <h4 className={styles.title}>{offer.title}</h4>
      

        {/* {
          token && <h4><Link to={{pathname: offer.trackingLink}} target="_blank">{offer.title}</Link></h4>
        }
          {
            !token && <h4>{offer.title}</h4>
          } */}
        <div className={styles.extra}>
          {/* <Link to={"/conversions"}>
            <i className="lab la-ethereum" />
              // <span className="iColor">{offer.amount}</span> 
              {offer.amount}
          </Link> */}
          {/* <Link to={"/OfferWall/user/"}>
            <i className="las la-user-tie" />
            
          </Link> */}
        </div>
        {/* <p>{offer.offerDescription}</p> */}

        {/* <div className={styles.btn}>
          <Button url={offer.trackingLink} hoverType="solid-gray-tb">
            Read More
          </Button>
        </div> */}

        {/* <Button click={() => {
          setOfferDetails(offer);
        }} hoverType="solid-gray-tb">
            Read More
          </Button> */}
        
      </div>
    </CardImage>
      </a>
  );
};
