import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
//import Form from 'react-bootstrap/Form';
// import './Login.css';
//import '../CSS/LoginNew.css';
import '../../App.css';
//import { Header } from "../widgets";
import { useCustomState } from '../../state/state';
import { getTrackingLink } from '../../services/basicGet';
import { login } from '../../services/authenticate';
import { useNavigate } from "react-router-dom";

// turn to service
async function loginUser(credentials) {
  return fetch('https://api.upmatic.io/Authenticate/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(credentials),
  })
    .then((data) => {
      return data.json();
    })
    .then((result) => {
      return result;
    });
}

export default function Login({ setToken, emailRef, updateToken }) {
  // const [state, actions] = useCustomState()[0];
  const [state, actions] = useCustomState();
  const [errorMessage, setErrorMessage] = useState();
  const [email, setUserName] = useState();
  const [password, setPassword] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    // Reset the error message state when the component is unmounted.
    return () => {
      setErrorMessage(undefined);
    };
  }, [state.modal]);

  function swapModal() {
    actions.toogleModal();
    actions.toogleSignup();
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    // eslint-disable-next-line
    if (password == null || password == '') {
      setErrorMessage('Please input username and password!');
      return;
    }

    // const token = await loginUser({
    //   email,
    //   password,
    // });
    actions.toogleLoading(true);
    login(email, password, (token) => {
      setUserName(null);
      setPassword(null);

   
    actions.updateUser(email);
    actions.updateLoggedIn(true);
    localStorage.setItem('user', email);
    console.log('user: ' + state.user);

    if(window.gtag){
      window.gtag('event', 'login_success', {
        'app_name': 'upmatic.io',
        'screen_name': 'LoginModal'
      });
    }
   
      setErrorMessage(null);
 
    actions.toogleLoading(false);
    actions.toogleModal(false);
    if(state.loginSuccess != null){
      state.loginSuccess();
    }

    }, function(err){
      if(err?.status == 'Failed' && err?.message != null){
        setErrorMessage(err.message);
      }
      if(window.gtag){
        window.gtag('event', 'login_failed', {
          'app_name': 'upmatic.io',
          'screen_name': 'LoginModal'
        });
      }
      actions.toogleLoading(false);
    });
  };
  return (
    <>
      {/* <h1 className='sign-up'></h1>   */}
      {/* <Header img={state.data.header_bgs.about}>Login</Header> */}
      <div className="auth-wrapper">
        <div className="auth-inner">
          {/* <div className="wrappert"> */}
          {/* <h1>Sign in</h1> */}
          <h1>Sign In Login</h1>
          {errorMessage && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#FAEFEF',
                height: '40px',
                marginTop: '3px',
                marginBottom: '8px',
                borderRadius: '4px',
                padding: '5px',
                boxShadow: '2px 2px 5px  #EBDEDF',
                textAlign: 'center',
              }}
            >
              <div
                style={{
                  textAlign: 'center',
                  color: '#ff0000',
                  fontSize: '14px',
                  lineHeight: '1.5',
                  fontWeight: 500,
                }}
              >
                {errorMessage}
              </div>
            </div>
          )}

          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              {/* <p>Email</p> */}
              <label>Email address</label>
              <input
                ref={emailRef}
                size="50"
                className="form-control inputEmail"
                autoComplete="username"
                type="text"
                placeholder="Email Address"
                onChange={(e) => setUserName(e.target.value)}
              />
            </div>

            <div className="mb-3">
              {/* <p>Password</p> */}
              <label>Password</label>
              <input
                size="50"
                className="form-control inputPassword"
                autoComplete="current-password"
                type="password"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div>
              <p className="forgot-password text-right">
                Forgot <a href="/forgot">Password? </a>
              </p>
            </div>
            <div className="d-grid">
              <Button
                className="btn btn-primary btnSubmit"
                type="submit"
                variant="primary"
              >
                Sign in
              </Button>
            </div>
            <div className="d-grid">
              {/* <Link to="/sign-up">
                        <Button className="btn btn-primary btnSubmit" type="submit" variant="light" >Create new account</Button>
                    </Link> */}
              <p className="forgot-password text-right">
                Don't have an account?{' '}
                <a href="#" onClick={() => swapModal()}>
                  Signup
                </a>
              </p>
              {/* <p className="forgot-password text-right">
                    Don't have an account? <i onClick={() => swapModal()} className="las la-times" /><span>Signup</span>
                </p> */}
            </div>
          </form>
          {/* </div> */}
        </div>
      </div>
    </>
  );
}

Login.prototype = {
  setToken: PropTypes.func.isRequired,
};
