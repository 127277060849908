import React, { useEffect, useState } from 'react';
import '../../../App.css';
import Login from '../../Login/Login';
import useToken from '../../../useToken';

export default ({ data, onBack }) => {
    const { token, setToken } = useToken();
    //console.log(data);

    useEffect(() => {

    }, [token])

    if (!token) {
        return <Login setToken={setToken} />;
    }


    return (
        <div className="col-lg-8 mx-auto p-4 py-md-5">
            <header className="d-flex align-items-center pb-3 mb-5 border-bottom">            
                <a href={ data.trackingLink } alt={ data.title}>
                    <img src={ data.creativeImage } alt={data.title} />
                </a>
            </header>
            <main>
                <h2>{data.title}</h2>

                <p className="fs-5 col-md-8">
                { data.offerDescription }    
                </p>
        
                <p className="fs-5 col-md-8">
                offerDescription, deviceType, requirementType, trackingLink, impressionLink, creative, events, id, title, creativeImage, rewardDescription, amount, status
                </p>
      
                {/* <div className="mb-5">
                    <div style={{ backgroundColor: '#CCC'}}>
                        <p className="fs-5 col-md-8">
                        offerDescription, deviceType, requirementType, trackingLink, impressionLink, creative, events, id, title, creativeImage, rewardDescription, amount, status
                        </p>                
                    </div>
                </div> */}

                <div className='row g-5'>
                    {}
                    <a href={ data.trackingLink } className="btn btn-primary btn-lg px-4">Click Here</a>
                </div>
      
                <hr className="col-3 col-md-2 mb-5" />
      
                <div className="row g-5">
                    <div className="col-md-6">
                        <h2>Reward Steps: { data.requirementType }</h2>
                        <p>{ data.rewardDescription } </p>
                        <ul className="icon-list ps-0">
                            <li className="d-flex align-items-start mb-1">
                                { data.requirementType }
                            </li>
                            <li className="d-flex align-items-start mb-1">
                                { data.rewardDescription }
                            </li>
                        </ul>
                    </div>      
                    <div className="col-md-6">
                        <h2>Reward Amount: { data.amount }</h2>
                        <p>Read more detailed instructions and documentation on using or contributing to Bootstrap.</p>
                        <ul className="icon-list ps-0">
                            <li className="d-flex align-items-start mb-1">
                                { data.amount }
                            </li>
                            <li className="d-flex align-items-start mb-1">
                                { data.status }
                            </li>
                            <li className="d-flex align-items-start mb-1">

                            </li>
                            <li className="d-flex align-items-start mb-1">

                            </li>
                            <li className="d-flex align-items-start mb-1">

                            </li>
                        </ul>
                    </div>                    
                </div>
                <div className='row g-5'>
                        <a href="#" className="btn btn-primary btn-lg px-4" onClick={onBack}>Back</a>
                </div>

                <div className="row g-5">
                    <h2>Events</h2>
                    <p>yada yada</p>
                    {/* <ul className="icon-list ps-0">
                        { data.events.map( event => <li key={ event.id } className="d-flex align-items-start mb-1">{ event.name }</li>)}
                    </ul> */}

                    <ul className="icon-list ps-0">
                        { data.events ?
                            data.events.map( event => (<li key={ event.id } className="d-flex align-items-start mb-1">{ event.name }</li>
                            ))
                            : <li className="d-flex align-items-start mb-1">No Events</li>
                        }
                    </ul>
                </div>
            </main>

            <footer className="pt-5 my-5 text-muted border-top">
            upmatic.io - RewardWall &middot; &copy; 2024
            </footer>
        </div>
    );
  };
  