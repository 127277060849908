import React from "react";
import "./Backdrop.css";
import { useCustomState } from "../../../state/state";

export default ({extraClassName}) => {
  const [state, actions] = useCustomState();

  const hideContent = () => {
    state.sidebar && actions.toogleSidebar();
    state.modal && actions.toogleModal();
    state.request.modal && actions.toogleRequest();
    state.video && actions.toogleVideo();
  };

  return <div className={extraClassName ? ["backdrop", extraClassName].join(" ") : "backdrop" } onClick={hideContent} />;
};
