import React, { useState } from 'react';
import { useCustomState } from "../state/state";
import '../App.css';
//import { Button } from './Buttons/Button';
import { Button, Link } from "../components/elements";
import { Layout } from './layouts';
//import { Link } from 'react-router-dom';
import { sanitizeInput } from '../services/utils';
import logger from '../services/EventLoggerSystem';

function Subscribe() {
  const [state, actions] = useCustomState();
  const [errors, setErrors] = useState({});
  const [isSubmitSuccess, setIsSubmittedSuccess] = useState(false);
  const [isSubmitError, setIsSbmittedError] = useState(false);
  const [subscribeAlert, setSubscribeAlert] = useState({ visible: false, message: '', type: ''});

  const [formData, setFormData] = useState({
    email: '',
  });  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
        ...prevState,
        [name]: value
    }));
  };

  // const resetFormData = () => {
  //   setFormData({});
  // }

  // Basic email validation
  const isValidEmail = email => /\S+@\S+\.\S+/.test(email);

    // Validate form data
    const validateFormData = (data) => {
      let isValid = true;
      const newErrors = {};

      if (!isValidEmail(data.email)) {
          isValid = false;
          newErrors.email = 'Invalid email address';
      }
      // Add more validation checks as needed

      setErrors(newErrors);
      return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
        // Client-side validation and sanitization logic here
        // Sanitize form data
        const sanitizedData = Object.keys(formData).reduce((acc, key) => {
          acc[key] = sanitizeInput(formData[key]);
          return acc;
      }, {}); 
      
      // Validate form data
      if (!validateFormData(sanitizedData)) {
        console.error('Validation failed');
        return; // Stop form submission
      }
    
    // Send email to the backend
    try {
      //throw new TypeError('This is a type error message.');
      if (process.env.NODE_ENV === 'production') {
        const response = await fetch('/e/signup', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(sanitizedData),
        });

        if(response.ok){
          // event Google Analytics
          if(window.gtag){
            window.gtag('event', 'signup_newsletter_success', {
              'app_name': 'upmatic.io',
              'screen_name': 'Subscribe'
            });
          }

          //console.log('Email Sent successfully')
          //console.log(sanitizedData);
          logger.log(`Email Sent successfully: ${sanitizedData}`, logger.DebugLogLevel.info);

          // clear input box
          setFormData({ email: '' });

          setIsSubmittedSuccess(true);
          //setSubscribeAlert({ visible: true, message: 'Thank you for your submission', type: 'success'});
        } else {
          //console.log(response);
          //console.log('Failed to send email');
          // event Google Analytics
          if(window.gtag){
            window.gtag('event', 'signup_newsletter_failed_to_send_email', {
              'app_name': 'upmatic.io',
              'screen_name': 'Subscribe'
            });
          }

          logger.log('Failed to send email', logger.DebugLogLevel.info);
          //console.log(sanitizedData);

          logger.log({
            message: 'Failed to send email',
            response: response,
            data: sanitizedData
          }, logger.DebugLogLevel.warn);
        }
      } else if (process.env.NODE_ENV === 'development'){
        //console.log(sanitizedData);
        logger.log('development mode set', logger.DebugLogLevel.info);

        logger.log({
          message: 'development mode set JSON response WARN triggered',
          data: sanitizedData
        }, logger.DebugLogLevel.warn);
        
        logger.log('development mode set JSON response WARN triggered', logger.DebugLogLevel.warn);

        // reset form
        //resetFormData();

        setIsSubmittedSuccess(true);
        console.log(`Submitted email: ${formData.email}` );
        logger.log(`Submitted email: ${formData.email}`, logger.DebugLogLevel.info);
      }
    } catch (error) {
        //console.error('Error', error);
        logger.log(`Error: ${error}`, logger.DebugLogLevel.error);
        //logger.DebugLogLevel(5);

        setIsSbmittedError(true);
        // setSubscribeAlert({
        //   visible: true,
        //   message: error.message,
        //   type: 'error'
        // });
    }
  };

  return (
    <Layout classNameExtra="subscription-background">
    <div className='subscription-container'>
      <img className='bottomLeft' src={ state.data.homeHeaderImgs.EthAndPhone } alt="Mobile phone with Ethereum symbol floating abovt it" />
      <div className='content-wrapper'>
        <section className='subscription-subscription'>
          <p className='subscription-subscription-heading'>
            Join the Crypto Rewards Newsletter to receive our best deals
          </p>
          <div className='input-areas'>
            <form onSubmit={handleSubmit}>
              <input
                style={{ float: 'left', marginTop: '0px', height: '42px', marginRight: '12px' }}
                className={`contactInputEmail ${errors.email ? 'is-invalid' : ''}`}
                id="email"
                name='email'
                type='email'
                value={formData.email}
                placeholder='Your Email'
                onChange={handleChange}
                required
              />
              {/* <Link to="/offers"> */}
                <Button
                  to=""
                  round
                  type="solid-footerBgColor-tw"
                  hoverType="solid-color-tw"
                  click={ handleSubmit }>
                  Subscribe
                </Button>

              {/* <button
                type="submit" 
                style={{ marginLeft: "20px"}}
                className='btn btn-primary btn-lg'
              >
                Subscribe
              </button> */}
            </form>
          </div>
          <div
            className='subscription-thank-you-wrapper'>
            {/* New div for the thank you message */}
            { isSubmitSuccess ? 
                <span
                  style={{ display: isSubmitSuccess ? 'block' : 'none' }} 
                  className='subscription-thank-you'><strong>Thank you for your submission</strong><br />confirmation email sent please 'confirm' by checking your email or junk folder
                </span>
              :
              <span
              style={{ display: isSubmitError ? 'block' : 'none' }}
              className='subscription-thank-you'>Submit Error, please try again later</span> 
            }
            
          </div>

          {/* <div
            className='subscription-thank-you-wrapper'>
            
          </div> */}
          <div className='subscription-text-bottom'>
            <p className='subscription-subscription-text'>
              You can unsubscribe at any time.
            </p>
          </div>
        </section>
      </div>
    </div>
    </Layout>
  );
}

export default Subscribe;
