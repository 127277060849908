import React, { useState } from 'react';
import Card from './Card';
import Details from './Details';

function ParentComponent() {
  const [cardsData, setCardsData] = useState([
    // Populate with your data
    { id: 1, title: 'Card 1', details: 'Details of Card 1' },
    { id: 2, title: 'Card 2', details: 'Details of Card 2' },
    { id: 3, title: 'Card 3', details: 'Details of Card 3' },
    // ... other cards
  ]);
  const [selectedCard, setSelectedCard] = useState(null);

  const handleMoreDetails = (data) => {
    setSelectedCard(data);
  };

  const handleBack = () => {
    setSelectedCard(null);
  };

  return (
    <div>
      {selectedCard ? (
        <Details data={selectedCard} onBack={handleBack} />
      ) : (
        cardsData.map(card => (
          <Card key={card.id} data={card} onMoreDetails={handleMoreDetails} />
        ))
      )}
    </div>
  );
}

export default ParentComponent;
