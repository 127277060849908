import React from 'react'
import Accordion from 'react-bootstrap/Accordion';

const Faqs = () => {
    const accordionData = [
        {
          id: "0",
          title: 'How To Redeem',
          content: `To redeem your rewards, you must first accumulate a minimum of 5,000 points. Once you have reached or exceeded this threshold, visit the 'Rewards' page where you can view all successfully completed offers—each listed with details and the points earned. To redeem, simply add the desired offers to your cart, ensuring the total points value meets or exceeds the 5,000-point minimum. When your cart's total points are sufficient, you can choose to receive a gift card for the accumulated amount. Please follow the prompts to complete your redemption.`,
        },
        {
          id: "1",
          title: 'How To Complete an Offer',
          content: `Explore the deals available to you. Select and start a deal, and carefully review the requirements and rewards of each deal. Choose the ones that interest you. Complete the tasks according to the deal's requirements. Once the requirements are successfully met, the reward should automatically be available to redeem. Please note that there can sometimes be a delay in the issuance of rewards, typically 24-48 hours.`,
        },
        {
          id:"2",
          title: 'Request for Support',
          content: `If you need assistance or have any issues, please use our <a href="/Support" alt="support page">support</a> request form available on our website. You can find the form by navigating to the 'Support' section. Fill out the form with all relevant details about your issue, and our support team will get back to you as soon as possible to help resolve your concerns. Please include any specific details or screenshots that might help us understand and address your query more effectively.`,
        },
        {
          id:"3",
          title: 'Who is eligible to register with Upmatic?',
          content: `Registration with Upmatic requires being 18 years of age or older and residing within the United States.`,
        },
        {
          id:"4",
          title: 'Is it permissible to employ a VPN for altering my location to the United States?',
          content: `Creating an account from outside the United States using a VPN can lead to cancellation and forfeiture of all funds, as this is prohibited per our Terms of Service.`,
        },
        {
          id:"5",
          title: 'How do I generate income with upmatic?',
          content: `It's essential that you live in the United States and possess a U.S. address. Following this, you're free to explore and engage with offers that capture your interest to begin earning.`,
        },
        {
          id:"6",
          title: 'How can I receive payment?',
          content: `After accumulating at least $5 in your account, you are eligible to redeem any amount above $5 for a selected gift card. There will be a verification period for all redeemed amounts, varying between 1 to 31 days to confirm transactions, typically averaging around 14 days.`,
        },
        {
          id:"7",
          title: 'When am I eligible to withdraw my earnings?',
          content: `Your earnings become eligible for withdrawal once your account balance reaches at least $5.00. Should you have previously withdrawn earnings, your balance must again meet or exceed $5.00 before you can make another withdrawal. Please note, any rewards credited to user accounts undergo a validation period of 1-31 days for transaction verification.`,
        },
        {
          id:"8",
          title: 'How can I redeem my earnings?',
          content: `Navigate to the Upmatic cart page and fill out the necessary information to initiate a payout. Select the desired gift card type and specify an amount greater than $5 to proceed with the cash-out process.`,
        },
        {
          id:"9",
          title: 'How long before I can redeem my earnings?',
          content: `Redemptions are usually completed within 1 to 3 business days. If there's an ongoing investigation by our fraud prevention team, this process could extend. Remember, all rewards credited to user accounts are subject to a verification period of 1-31 days for transaction validation.`,
        },
        {
          id:"10",
          title: 'What is the typical waiting period for transaction validation, and what is expected from users during this time?',
          content: `The typical waiting period for validating transactions is around 14 days. We greatly appreciate your understanding and patience during this process!`,
        },
        {
          id:"11",
          title: 'What is meant by "Funds Pending"?',
          content: `"Funds Pending" indicates a verification period to ensure compliance with offer requirements. The dates on your earnings page show when offers were added and when funds are set to be released. This period allows us to verify transactions, guard against fraud, and manage returns, ensuring all activities are legitimate.`,
        },

      ];
  return (
    <>
      <div className='secondaryFaqContent'>
        <h4>
          Answers To Common Questions
        </h4>

        <hr />

        <Accordion defaultActiveKey="0">
          {accordionData.map(({ id, title, content }) => (
            <Accordion.Item eventKey={id} key={id}>
              <Accordion.Header>{title}</Accordion.Header>
                <Accordion.Body dangerouslySetInnerHTML={{ __html: content }} />
            </Accordion.Item>
          ))}
        </Accordion>
    </div>
    </>
  )
}

export default Faqs

const fqWrapper = {
    //width: '90%',
    margin: '2rem auto',
    maxWidth: '1000px'
  };
  
  const accordionItem = {
    float: 'left',
    fonstSize: '500',
  };