import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useRouteChangeLogger = () => {
  const location = useLocation();
  const hostname = window.location.hostname;
  //console.log(hostname);

  useEffect(() => {
    const logRouteChange = () => {
      // Only log in production environment
      if (process.env.REACT_APP_NODE_ENV === 'production') {
        fetch('/logs/log', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            path: location.pathname + location.search,
            timestamp: new Date().toISOString(),
          }),
        });
      }
      else if('development'){
        // console.log("isProduction false");
        console.log(location.pathname + location.search + " --- : " + Date().toString());
      }
    };
    
    logRouteChange();
  }, [location]);
}

export default useRouteChangeLogger;
