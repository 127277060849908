import React, { useEffect } from 'react';
import {Container, Table, Row, Col, Button} from 'react-bootstrap';
//import Footer from '../../Footer';
import {
    Header,
  } from "../../widgets";
  import { useCustomState } from "../../../state/state";

export default function Terms({ pageTitle="pageTitle" }) {
    const state = useCustomState()[0];

    // for use by page's title
    useEffect(() => {
        document.title = pageTitle;
    }, [pageTitle]);

    return (
        <>
            {/* <Header img={state.data.header_bgs.about}>Terms of Service</Header> */}
            <div style={{padding: '50px 0 50px 0'}}>
            <Container>
                <Row>
                    <Col>
                        <h1>Terms of Service</h1>
                        <p>LAST UPDATED and effective: June 15, 2023, v. 10‍</p>

                        
                        <h2>ACCEPTANCE OF TERMS</h2>
                        <p>
                        Please read these terms and conditions (this “Agreement” or these “Terms”) carefully. This Agreement is effective when the User clicks on the “AGREE & CONTINUE” button during the TempName account creation process (the “Effective Date”) and by doing so, User agrees to be bound by the terms of this Agreement.
                        </p>
                        <p>
                        These Terms constitute an agreement, which includes the privacy policy (“Privacy Policy”) and the community guidelines (“Community Guidelines”) by and between TempName inc., (“Tempname” or “we”) and any individual user (the “User” or “you”) that uses or accesses the Services (defined hereinafter).
                        </p>
                        <p>
                        TempName reserves the right to update and change these Terms and the Services at any time and any new services, features or tools which are added to the current Services shall also be subject to the Terms of Use. You are advised to review these Terms from time to time for updates and changes that may impact you. Any use of the Services after such updates and changes will mean that you accept them. You can always review the last updated version of the Terms at https://www.tempname.com/terms-of-use. Please also review from time to time our Privacy Policy at https://www.tempname.com/privacy and our Community Guidelines http://www.tempname.com/community-guidelines.
                        </p>

                        <h2>SERVICES</h2>

                        <p>The Services offered by TempName under the Terms consist of the TempName website and mobile applications (the “App”) offering rewards to Users in exchange to download and review and/or use any game suggested in the App (the “Services”).
                        </p>

                        <h2>ACCOUNT & ELIGIBILITY</h2>

                        <p>
                        Some of the Services require that you sign up for a User account. Only one (1) User account may be set up per User. Only users 18 years or older are permitted to register for a User account. Transferring or selling a User account to third parties for their own purposes is not permitted. By using the Services, you represent and warrant that (a) all information you submit to TempName are truthful and accurate; (b) you will maintain the accuracy of all such information; (c) you will uphold and respect these Terms at all times; (d) your use of the Services does not violate any applicable law or regulation; (e) you will safeguard your account information and will supervise and be completely responsible for any use of your Account by you and anyone other than you. Your account may be deleted without warning at Tempname’s sole discretion if we believe that you violate any of these Terms.
                        </p>
                        <p>
                        Some of the Services that we provide require that you enable the "Display of Other Apps" option. Enabling this option will allow us to better understand the types of games you prefer to play so that we can suggest to you other similar games. You will receive a notification if you need to enable this option.
                        </p>
                        <p>
                        Some of our Services that we provide allow for a “Guest” or “Continue as Guest” option allowing restricted access to the Service. We reserve the right to modify restrictions applied to certain features when accessing the Service through a Guest option. You will not have full, unrestricted access to all features of the Service without completing a sign up for a User account.
                        </p>
                        
                        <h2>RULES OF USER CONDUCT</h2>
                        <p>
                        Our App contains a social feature allowing the User to interact with other User(s). All Users are subject to our Community Guidelines detailing the rules of user conduct with respect to interacting with other Users on our App. TempName reserves the right to restrict User access to the social feature or indefinitely ban a User account for violation of our Community Guidelines.
                        </p>
                        <p>
                        The User may only set up one account in the App. It is prohibited to create multiple accounts or transfer or sell a User account to third parties. You are not allowed to use a virtual private network (“VPN”), proxy or any other means to obscure the true source of traffic when using the Services. You are not allowed to use any other electronic means such as an internet bot or web robot (“Bot”) to generate automated views, clicks or conversions or employing any misleading practices such as repeated manual clicks to artificially inflate user activity and/or revenues when using the Services. Any User activity that uses a VPN, proxy or Bot will be considered as fraudulent activity and will be blocked by our fraud prevention system. The use of a VPN, proxy or Bot can cause your device to be permanently blacklisted and your User account to be either suspended or terminated.
                        </p>
                        <p>
                        TempName reserves the right to indefinitely ban any user found to be using an autoclicker program or any similar software that allows a user to falsify data relating to their phone's input, or that allows them to appear to be using their phone when in reality they have left it idle.
                        </p>
                        TempName reserves the right to indefinitely ban any user found to be accessing the application through an emulator of any kind, regardless of the platform on which the emulator is being used.
                        <p>
                        TempName reserves the right to ban any account associated with software that can be used to modify games, or software that can unlock in-game content for free that would otherwise need to be purchased.
                        </p>
                        <p>
                        TempName reserves the right to ban any account associated with disposable email addresses that automatically forward messages to an alternate address, or that can automatically visit and open verification links that are sent to them.
                        </p>
                        <p>
                        TempName reserves the right to nullify all units earned on any account that has been banned, and to disable the redemption of rewards on all banned accounts, regardless of the reason for which the account was banned.
                        </p>
                        <p>
                        TempName reserves the right to indefinitely ban any user found to be using a rooted device or any device for which the user has been granted root access or privileged control. Rooted devices are not supported devices and may at any time be blocked from us.
                        </p>
                        <p>
                        TempName reserves the right to reject any gift card purchase request issued from an account associated with a rooted device, or from any account banned for fraudulent activity.
                        </p>
                        <p>
                        TempName reserves the right to permanently ban any user account created by an individual under the age of 18.
                        </p>
                        <p>
                        TempName reserves the right to ban any account that appears to be using, downloading or accessing the app fraudulently. This includes but is not limited to users who download the App through a means other than the Google Play store, users with privileged control over their devices, users with suspicious third-party firmware on their device, users manipulating their IP address, users unable to complete the face verification or phone verification processes for fraud verification purposes, users using software that makes them appear active when they are idle, users accessing the app from an emulator of any kind, or any user attempting to fraudulently earn units through any means other than playing games.
                        </p>
                        <p>
                        It is prohibited to access the TempName application on any device other than an Android mobile phone or tablet and doing so can result in an account ban or rejection of an order.
                        </p>

                        <h2>TERM</h2>
                        <p>
                        This Agreement will remain in full force and effect while you use the Services and/or have a TempName account (the “Term”). You may stop using the Services at any time, for any reason, by uninstalling the App from your mobile device. TempName may terminate or suspend your account at any time without notice if TempName reasonably believes that you have breached this Agreement. After your account is terminated, this Agreement will terminate, except for the provisions of the sections labelled “Payment & Fees”, “Intellectual Property”, “No Liability”, “No Warranty”, “Indemnity”, “Severability, Force Majeure, Entire Agreement & Headings”, “Representations and Warranties”, and “Governing Law and Language”.
                        </p>
                        <p>
                        Upon termination of this Agreement, User shall discontinue its use of the Services. However, termination of this Agreement by TempName shall not limit User's obligation to pay any outstanding fees, if applicable, nor restrict TempName from pursuing any other remedies available to it, including injunctive relief.
                        </p>
                        <p>
                        User agrees that following termination of User’s account and/or use of the Services, TempName may immediately deactivate User’s account and delete User data and accumulated rewards. User further agrees that TempName shall not be liable to User nor to any third party for any termination of User access to the Services or deletion of User data. The foregoing shall not apply if such termination or deletion is attributable to Tempname’s gross negligence or willful misconduct.
                        </p>

                        <h2>PAYMENT & FEES</h2>
                        <p>
                        User agrees to pay to TempName any fees for each Service that User purchases, uses, or subscribes to in accordance with the pricing and payment terms presented by TempName for such Service. Where applicable, you will be billed using the billing method selected by the User at the moment of purchase. TempName refunds as prescribed in the applicable law, and for more details, please refer to the respective webpage for sales.
                        </p>

                        <h2>REWARDS</h2>
                        <p>
                        TempName offers loyalty points, used as virtual currency in the TempName apps and referred to as “Units”, to Users in exchange for testing, reviewing and/or using the mobile games listed in the App. TempName does not reward for time spent in App but rather for real gametime. Leaving the App open does not constitute gameplay. Users can redeem Units for different rewards offered by TempName, such as gift cards, gift certificates, prepaid cash cards or content codes (collectively “Rewards”). The Rewards cannot be redeemed for cash or in any way resold, exchanged or returned for a cash refund, unless required by law. Rewards may be redeemed in countries where applicable merchants and services are available. TempName is not responsible for any valid Reward which user is unable to redeem due to their age, situation, or country of residence. TempName is not responsible for any lost, stolen, or malfunctioning Rewards except when it is caused by gross negligence or willful misconduct of TempName.
                        </p>
                        <p>
                        For all users 18 years or older, when redeeming a reward through our Shop, we may verify your face via a ‘video selfie’ using a third party face verification technology. We use this information exclusively for the purpose of fraud prevention and to ensure that prizes are not earned through bots, automated processes or fraudulent use of software. We will use your ‘video selfie’ exclusively for fraud prevention. Upon redeeming a reward from our Shop, you will be prompted to provide a video selfie using your mobile device’s camera. This video selfie provides us with a face map (a mathematical representation of your face generated through our third party provider, Facetec). This video selfie is encrypted on your mobile device before being sent to our servers for processing, and is linked to your user account to ensure the uniqueness of your user registration within the mobile application. If you do not want to redeem rewards through the Shop, you can continue to use all other features of the mobile application without providing a video selfie. For more information, please refer to our Privacy Policy available at https://www.tempname.com/legal/privacy.
                        </p>
                        <p>
                        Until October 31, 2021, existing users between the ages of 13 and 17 years old will be required to provide a phone number to verify their account upon redeeming a reward through our Shop. We use this information exclusively for the purpose of fraud prevention and to ensure that prizes are not earned through bots, automated processes or fraudulent use of software. Upon redeeming a reward from our Shop, you will be prompted to provide a phone number, which is linked to your user account to ensure the uniqueness of your user registration within the mobile application. If you do not want to redeem a reward from our Shop, you can use all other features of the mobile application without providing your phone number.
                        </p>
                        ‍
                        <h2>DONATIONS</h2>

                        <p>An opportunity to redeem a number of Units for a donation to be made by TempName to a charitable and/or other not-for-profit cause or organization may be made available from time to time to User via the App (each a “Donation”). User agrees that if User chooses to redeem Units for such a Donation, that all applicable terms presented in the App for such Donation shall apply in addition to these Terms and that User’s Units will be reduced as per the applicable terms of such Donation. Each and every Donation will be made by TempName Inc. Users who redeem Units for Donation through TempName are not eligible for a tax receipt. TempName is unable to refund Units once you have accepted to redeem Units for a Donation.
                        </p>

                        <h2>CONTESTS</h2>
                        <p>
                        TempName may from time to time launch certain Contests (each one a “Contest”) available to certain users from App. Contests are only available only in jurisdictions where applicable laws permit them, so you may or may not be able to participate in any Contest depending on your jurisdiction. Each available Contest will be subject to such Contest’s own terms and conditions (“Contest Terms”) which will apply in addition to these Terms. For each applicable Contest, please read the Contest Terms fully and carefully before participating. Each Contest is sponsored by TempName and TempName reserves the right to cancel, suspend, withdraw, or amend such Contest or the Contest Terms of a Contest in any way, without prior notice. It is the Contest entrant (the “Entrant”) responsibility to check the Contest Terms periodically for changes. An Entrant’s continued use of or participation in a Contest following the posting of any changes to the Contest Terms constitutes acceptance of those changes. 
                        </p>

                        <h2>INACTIVITY</h2>
                        <p>
                        TempName reserves the right to delete without notice any account which has been inactive for a consecutive period of one hundred eighty (180) days. An account is deemed inactive when: (i) no Units have been redeemed or credited; (ii) no new referral sign-ups have occurred using the account’s referral link; (iii) there has been no access or attempt to access the Services. If the account contains any Units or Rewards, they will be considered void without notice.
                        </p>

                        <h2>LICENSE</h2>
                        <p>
                        During the Term, TempName grants User a limited, non-exclusive, non-transferable, non-sublicensable, revocable license to use the Services on the terms set forth in this Agreement. User agrees that all rights, title, and interest in and to all the intellectual property rights in the Services, and all modifications, extensions, scripts and other derivative works of the Services provided or developed by TempName are owned exclusively by TempName or its licensors. All rights not granted to User in this Agreement are reserved by TempName.
                        </p>

                        <h2>LICENSE RESTRICTIONS</h2>
                        <p>
                        NOTICE: For privacy and fraud prevention purposes, as of November 1, 2021, the TempName mobile application will no longer support existing user accounts of individuals under the age of 18. Current users under the age of 18 will be able to continue using the mobile application until this date. As of August 25, 2021, individuals under the age of 18 are prohibited from creating a TempName user account.
                        </p>
                        <p>
                        User shall not (i) create an Account or access the Service if they are under the age of 18; (ii) decompile, disassemble, or otherwise reverse engineer or attempt to reconstruct or discover any source code, underlying ideas, algorithms, file formats or programming or interoperability interfaces of or used by the Services, by any means whatsoever; (iii) distribute viruses or other harmful or malicious computer code via or into the Services; (iv) engage in any conduct that disrupts or impedes a third party’s use and enjoyment of the Services; (v) remove any product identification, copyright or other notices from the Services; (vi) sell, lease, lend, assign, sublicense, grant access or otherwise transfer or disclose the Services in whole or in part, to any third party; (vii) use the Services for time-sharing, service bureau or hosting purposes or otherwise use, resell, sublicense, distribute or transfer or allow others to use the Services to or for the benefit of third parties; (viii) modify or incorporate into or with other software or create a derivative work of any part of the Services; (ix) use the output or other information generated by the Services for any purpose other than as contemplated by this Agreement; (x) use the Services for any use other than User’s internal business use; (xi) use unauthorized modified versions of the Services, including without limitation, for the purpose of building a similar or competitive product or service or for the purpose of obtaining unauthorized access to the Services; or (xii) use the Services in any way that is contrary to applicable local, state/provincial, federal and foreign laws, including without limitation those relating to privacy, electronic communications and anti-spam legislation. TempName retains all title to, and, except as expressly licensed herein, all rights to the Services, all copies, derivatives, and improvements thereof and all related documentation and materials.
                        </p>
                        ‍
                        <h2>INTELLECTUAL PROPERTY</h2>
                        <p>
                        The Services contain copyrighted material, trademarks, and other proprietary information, including, but not limited to, text, software, photos, video, graphics, music and sound, and the entire contents of the Services are copyrighted as a collective work under copyright laws. TempName owns a copyright in the selection, coordination, arrangement, and enhancement of such content, as well as in the content original to it. User may not modify, publish, transmit, participate in the transfer or sale, create derivative works, or in any way exploit, any of the content, in whole or in part. Except as otherwise expressly permitted under copyright law, no copying, redistribution, retransmission, publication, or commercial exploitation of downloaded material will be permitted without the express permission of TempName and the copyright owner. In the event of any permitted copying, redistribution or publication of copyrighted material, no changes in or deletion of author attribution, trademark legend or copyright notice shall be made.
                        </p>
                        
                        <h2>CONFIDENTIAL INFORMATION</h2>
                        <p>
                        TempName and User shall each retain in confidence all information received from the other party pursuant to or in connection with this Agreement that the disclosing party identifies as being proprietary and/or confidential or that, by the nature of the circumstances surrounding the disclosure, ought in good faith to be treated as proprietary and/or confidential ("Confidential Information"), and will make no use of such Confidential Information except as necessary to fulfill their respective obligations under the terms and during the term of this Agreement. TempName may collect some Confidential Information from User which includes, without limitation, their name, email address, age, gender, profile picture, social media profiles and payment information. The restrictions set forth above will not apply to (i) information previously known to the receiving party without reference to the disclosing party's Confidential Information, (ii) information which is or becomes publicly known through no wrongful act of the receiving party, (iii) information that is independently developed by either of the parties, or (iv) information required to be disclosed pursuant to applicable law.
                        </p>

                        <h2>PRIVACY POLICY</h2>
                        <p>
                        Data protection is important at TempName. TempName collects and treats User information in accordance with its Privacy Policy available at https://www.tempname.com/privacy.
                        </p>

                        <h2>THIRD PARTY SERVICES</h2>

                        <p>
                        TempName may recommend, provide you with access to, or enable third party software, applications, products, services, or website links (collectively, “Third Party Services”) for your consideration or use. Such Third-Party Services are made available only as a convenience, and download, purchase, access, or use of any such Third-Party Services is solely between you and the applicable third-party services provider (“Third Party Provider”). Any use by you of Third-Party Services offered through the Services or the App is entirely at your own risk and discretion, and it is your responsibility to read the terms and conditions and/or privacy policies applicable to such Third-Party Services before using them. Under no circumstances shall TempName be liable for any direct, indirect, incidental, special, consequential, punitive, extraordinary, exemplary, or other damages whatsoever, that result from any Third-Party Services or your contractual relationship with any Third-Party Provider. These limitations shall apply even if TempName has been advised of the possibility of such damages.
                        </p>

                        <h2>NO WARRANTY</h2>
                        <p>
                        TempName works to keep the services bug-free and safe but the user agrees to use the services at user’s own risk. TempName is providing the services “as is” and “as available” without any representations and warranties, express, implied, or statutory, not expressly set out in these terms, including the implied warranties of merchantability, fitness for a particular purpose and non-infringement. In addition, neither tempname, its affiliates nor any of their respective employees, agents, mandataries, third party content providers or licensors make no representation, warranty, or guarantee regarding the reliability, timeliness, quality, suitability or availability of the services or any services or goods requested through the use of the services, or that the services will be uninterrupted or error-free.
                        </p>
                        <p>
                        All games featured in the Discover Weekly section of the app are recommended based on similar games that a user has installed on their device. The featured games are not hand-picked or vetted by TempName, and downloading these games carries the same risk as downloading any other game directly from the Google Play Store. TempName accepts no responsibility for any unintended consequences of selecting a game from the discover weekly playlist.
                        </p>

                        <h2>NO LIABILITY</h2>
                        <p>
                        In no event shall TempName or its respective suppliers or affiliates be liable for any special, indirect or consequential damages or any damages whatsoever resulting from loss of use, data or profits, or any damages or loss arising from the services provided by third party providers, whether in an action of contract, negligence or other civil liability action, arising out of or in connection with the use of any software, program or platform provided as part of the services except in the case of intentional or gross fault.
                        </p>
                        <p>
                        To the extent your data is used for research purposes, the limitation of liability clause above and the indemnity clause below are waived with respect to any harms suffered or liabilities incurred as a result of such research activities.
                        </p>

                        <h2>INDEMNITY</h2>
                        <p>
                        User agrees to defend, indemnify, and hold harmless TempName, its affiliates and their respective directors, officers, employees, agents, and mandataries from and against all claims and expenses, including without limitation, attorneys' fees (collectively the “Costs”), to the extent that such Costs are attributable to any breach by User, of any representations, warranties, or other obligations set forth in this Agreement or arising out of the use of the Services by User.
                        </p>

                        <h2>GOVERNING LAW AND LANGUAGE</h2>

                        <p>
                        These Terms of Service shall be construed in accordance with the laws applicable in the province of Quebec, Canada, without regard to its conflict of laws rules. User hereby agrees, for any dispute or controversy arising under or in connection with this Agreement or resulting from the Services or Corporation’s website shall be settled exclusively by arbitration in Montreal (Canada), in accordance with the Code of civil procedure of the province of Quebec (CQLR c C-25.01).

                        The parties to this Agreement confirm that it is their wish that: (1) this Agreement and all other related documents be prepared in English; (2) any litigation or dispute resolution proceeding be held in English; and (3) any litigation or dispute resolution document or court filing be drafted in English only.
                        </p>

                        <h2>SEVERABILITY, FORCE MAJEURE, ENTIRE AGREEMENT AND HEADINGS</h2>
                        <p>
                        If one or more of the provisions of this Agreement is held to be invalid, illegal, or unenforceable in any respect by a court of competent jurisdiction, then the validity, legality, and enforceability of the remaining provisions of this Agreement shall be unaffected. Neither party shall be deemed in default or otherwise liable for any delay in or failure of its performance under this Agreement (other than payment obligations) by reason of any act of God, fire, natural disaster, accident, act of government, shortage of materials or supplies, failure of transportation or communication or of suppliers of goods or services, or any other cause to the extent it is beyond the reasonable control of such party. This Agreement constitutes the entire agreement and understanding between the parties with respect to the subject matter hereof and supersedes all prior or contemporaneous written, electronic, or oral communications, representations, agreements, or understandings between the parties with respect thereto. The headings in this Agreement have been inserted solely for ease of reference and shall not modify, in any manner whatsoever, the meaning or scope of the provisions hereof.
                        </p>

                        <h2>REPRESENTATIONS AND WARRANTIES</h2>

                        <p>User represents and warrants that currently and throughout the Term (i) it is fully authorized to enter into this Agreement and that User is fully authorized to use the Services; and (ii) User is and will remain in compliance with all applicable laws and regulations with respect to its activities related to this Agreement, including but not limited to privacy laws. User agrees to comply with all of Tempname’s policies and all laws, rules and regulations relating to the use of the Services. Any violation of this section may result in loss of features, up to and including termination of User’s account. User will indemnify TempName for any costs, fines or damages incurred by TempName due to User's failure to comply with this section.
                        </p>
                    </Col>
                </Row>
            </Container>
</div>
        </>
    )
}