// GlobalRouteChangeLogger.js
import useRouteChangeLogger from './useRouteChangeLogger'; // Adjust the import path as needed

function GlobalRouteChangeLogger() {
  useRouteChangeLogger(); // Use the hook
  
  return null; // This component does not render anything
}

export default GlobalRouteChangeLogger;
