import React, { useState } from "react";
import { Link } from "react-router-dom";
import styles from "./Link.module.css";

export default ({ children, url, hoverStyle, style, click, after , extraClassName}) => {
  const [hover, setHover] = useState(false);

  return (
    <Link
      to={{ pathname: url}}
      onMouseEnter={() => setHover(!hover)}
      onMouseLeave={() => setHover(!hover)}
      style={hover ? hoverStyle : style}
      onClick={click}
      className={[styles.link, extraClassName].join(' ')}
      data-after={after}
    >
      {children}
    </Link>
  );
};
