import React from 'react'

const Confirm = () => {
  return (
    <>
      <div className='secondaryConfirmContent'>
        <h4>
        Confirm
        </h4>

        <hr />

        <p>
        We know that putting your money and effort into online rewards programs can be a gamble.  That's why upmatic.io is dedicated to building a rewards and loyalty experience where you know you're getting real value for your time.  We help you make the most of your online activity, earning and saving real money while you do it.            
        </p>

      </div>
    </>
  )
  }  
  export default Confirm