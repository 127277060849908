import React, { useState, useEffect } from "react";
//import { useNavigate } from "react-router-dom";
//import { Link } from "react-router-dom";
import "../CSS/Forgot.css";
import '../../App.css';
//import "../CSS/LoginNew.css";
import {Form, Button, Alert} from 'react-bootstrap';
import {
  Header,

} from "../widgets";
import { useCustomState } from "../../state/state";
import { forgotPassword } from "../../services/authenticate";

export default function Forgot ({ pageTitle="pageTitle" }) {
  const [state, actions] = useCustomState();
  const [email, setEmail] = useState("");
  const [checkEmail, setCheckEmail] = useState(false);
//  const [user, loading, error] = useAuthState(auth);
 // const navigate = useNavigate();

//   useEffect(() => {
//     if (loading) return;
//     if (user) navigate("/dashboard");
//   }, [user, loading]);

  // for use by page's title
  useEffect(() => {
    document.title = pageTitle;
  }, [pageTitle]);

  function handleSubmit(e){
      actions.toogleLoading(true);
      e.preventDefault();
      forgotPassword(email, 
        function(){
          setCheckEmail(true);
          actions.toogleLoading(false);
        },
        function(){
          setCheckEmail(true);
          actions.toogleLoading(false);
        });
    }

  return (
  <>
      <div className="auth-wrapper">
          <div className="auth-inner">
            <div className="mb-3">

            {
    checkEmail && <Alert key='primary' variant='primary'>
      Please check your email '{email}' for the reset password link.
  </Alert>
  }
  
            <Form onSubmit={(e) => {handleSubmit(e)}}>
              
              <label>Forgot Password</label>
              <p>Please enter the email adddress that you used to sign up, and we will send you an email with a link to reset your password.</p>
              <input 
                size="50"
                className="form-control inputEmail"
                autoComplete="username"
                type="email"
                placeholder="Email Address"
                value={email} 
                onChange={(e) => setEmail(e.target.value)} />

              <div className="d-grid">
                <Button
                  className="btn btn-primary btnSubmit"
                  // variant="primary"
                  type="submit"
                  >
                  RESET MY PASSWORD
                </Button>
              </div>
              <p className="forgot-password text-right">
                Already registered <a href="/login">sign in?</a>
              </p>
            </Form>
          </div>
        </div>
    </div>
  </>
  )
}