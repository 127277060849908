import React from "react";
import { Spinner } from "../../elements";
import Backdrop from "../Backdrop/Backdrop";
import './Loading.css';
import { useCustomState } from "../../../state/state";

export default function(){
    const [state, actions] = useCustomState();

return(<>
{
    state.loading == true &&
    <>
    <Spinner />
        <Backdrop extraClassName="spinnerBackdrop"/>
    </>
}
   
    </>
    );
}