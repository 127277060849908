import React, { useState, useEffect } from 'react'
import { resetPassword } from '../../services/authenticate';
import {Form, Button, Container, Alert} from 'react-bootstrap';
import "../CSS/Forgot.css";
import '../../App.css';
import { useCustomState } from '../../state/state';

export default function ResetPassword({ pageTitle="pageTitle" }) {
    const searchParams = new URLSearchParams(window.location.search);
    const email = searchParams.get("email");
    const code = searchParams.get("code");

    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const [error, setError] = useState();
    const [isError, setIsError] = useState(false);
    const [state, actions] = useCustomState();

    // for use by page's title
    useEffect(() => {
        document.title = pageTitle;
    }, [pageTitle]);

    const handleSubmit = e => {
        e.preventDefault();
        const data = {
            email,
            password,
            confirmPassword,
            code
        };

        actions.toogleLoading(true);
        resetPassword(data, 
        function(){
            actions.toogleLoading(false);
            window.location = '/login';
        }, 
        function(response){
            let errors = response?.password ?? response?. ConfirmPassword
            if(errors != null){
                var errorMessage = '';
                for(var i = 0; i < errors.length; i++){
                    errorMessage += errors[i] + ' ';
                }
                setError(errorMessage);
            }else{
                setError(null);
            }
            setIsError(true);
            actions.toogleLoading(false);
        });
    }

  return(
        <>
      <div className="auth-wrapper">
          <div className="auth-inner">

                {
                        isError && error != null && <Alert key='danger' variant='danger'>
                        {error}
                    </Alert>
                    }
                    {
                        isError && error == null &&
                        <Alert key='danger' variant='danger'>
                        Please request another reset password link. 
                        <Alert.Link href={window.location.origin + '/forgot'}>{window.location.origin + '/forgot'}</Alert.Link>
                    </Alert>
                    }

                {/* <Container>

                    <Form onSubmit={(e) => {handleSubmit(e)}}>
                        <h1>Reset Password</h1>
                        <Form.Group>
                            <Form.Control type="password" placeholder="New Password" onChange={(e) => setPassword(e.target.value)} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Control type="password" placeholder="Confirm Password" onChange={(e) => setConfirmPassword(e.target.value)} />
                        </Form.Group>
                        <Button variant="primary" type="submit" style={{'marginTop':'20px'}}>
                            Submit
                        </Button>
                    </Form>
                </Container> */}

                
                <Form onSubmit={(e) => {handleSubmit(e)}}>
                <div className="mb-3">
              <label>Password</label>
              <input 
                size="50"
                className="form-control inputPassword"
                type="password"
                placeholder="Password"
                value={password} 
                onChange={(e) => setPassword(e.target.value)} />
                             </div>

                             <div className="mb-3">
            <label> ConfirmPassword</label>
              <input 
                size="50"
                className="form-control inputPassword"
                type="password"
                placeholder="Confirm Password"
                value={confirmPassword} 
                onChange={(e) => setConfirmPassword(e.target.value)} />
                            </div>

              <div className="d-grid">
                <Button
                  className="btn btn-primary btnSubmit"
                  // variant="primary"
                  type="submit"
                  >
                  Submit
                </Button>
              </div>
            </Form>

            </div>
            </div>
        </>
  )
}