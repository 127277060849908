import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import RadioGroup from 'react-bootstrap';
import styles from './RedeemModal.module.css';
// import { Backdrop, ContactForm } from "..";
import { Backdrop, LoginForm } from '../../../components/ui';
import Login from '../../Login/Login';
import { useCustomState } from '../../../state/state';
import { redeem } from '../../../services/basicGet';
import { getGiftCards } from '../../../services/basicGet';

export default ({
  minimumTotal,
  cartTotal,
  modalVisible,
  setModalVisible,
  offers,
  setOffers,
}) => {
  const [state, actions] = useCustomState();
  const [email, setEmail] = useState();
  const [walletAddress, setWalletAddress] = useState();
  const [redeemType, setRedeemType] = useState('gift_card');
  const [errorMessage, setErrorMessage] = useState();
  const [successMessage, setSuccessMessage] = useState();
  const [giftCardSelections, setGiftCardSelections] = useState();
  const [selectedGiftCardId, setSelectedGiftCardId] = useState('');
  const [isRedeemBtnDisabled, setIsRedeemBtnDisabled] = useState(true);
  const emailRef = useRef();

  useEffect(() => {
    if(localStorage.getItem('user')){
      setEmail(localStorage.getItem('user'));
    }
    getGiftCards((cards) => {
      setGiftCardSelections(cards);
      if(cards != null && cards.length > 0){
        setSelectedGiftCardId(cards[0].id)
      }
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      redeemType == 'crypto' &&
      (walletAddress == null || walletAddress == '')
    ) {
      setErrorMessage(
        'Please provide a wallet address you want the coins to be transferred to.'
      );

      return;
    }

    setEmail(emailRef.current.value);

    if (redeemType == 'gift_card' && (email == null || email == '')) {
      setErrorMessage(
        'Please provide an email address you want the gift card to be sent to.'
      );

      return;
    }

    const offerIds = offers.cart.map((offer) => {
      return offer.id;
    });

    let data = {
      offerIds: offerIds,
      address: null,
      giftCardId: selectedGiftCardId,
      email: localStorage.getItem('user')
    };

    if (redeemType == 'crypto') {
      data.address = walletAddress;
    } else if (redeemType == 'gift_card') {
      data.address = email;
    }

    setErrorMessage(null);
    setSuccessMessage(null);

    redeem(
      data,
      function (response) {
        setOffers({
          cart: [],
          available: offers.available,
        });
        setSuccessMessage('Your reward is on the way! Confirmation: ' + response.data.confirmation);
        setIsRedeemBtnDisabled(true);
        //setModalVisible(false);
        // event Google Analytics SUCCESSFUL
          if(window.gtag){
            window.gtag('event', 'cart redeemed successful', {
              'app_name': 'upmatic.io',
              'screen_name': 'CartModalRedeem'
            });
          }
      },
      function (err) {
        setErrorMessage('Checkout was not successful, please try again.');
        // event Google Analytics FAILURE
        if(window.gtag){
          window.gtag('event', 'cart redeemed failed', {
            'app_name': 'upmatic.io',
            'screen_name': 'CartModalRedeem'
          });
        }
      }
    );
  };

  useEffect(() => {
    checkMinimumAmount();
  }, [cartTotal]);

  function checkMinimumAmount(){
    setIsRedeemBtnDisabled(parseFloat(cartTotal?.toString().replace(',', '')) < minimumTotal);
    if (parseFloat(cartTotal?.toString().replace(',', '')) < minimumTotal) {
      setErrorMessage('Minimum ' + minimumTotal + ' Reward points');
    } else {
      setErrorMessage();
    }
  };

  const ImageRadio = ({ src, alt, value, selectedValue, onChange }) => {
    const isSelected = selectedValue === value;
  
    return (
      <img
        src={src}
        alt={alt}
        style={{ cursor: 'pointer', maxWidth: '100px', margin:'5px', boxShadow: isSelected ? '0px 0px 5px 0.5px' : 'none'}}
        onClick={() => onChange(value)}
      />
    );
  };

  const handleOptionChange = (value) => {
    setSelectedGiftCardId(value);
  };

  return (
    <div className={[styles.wrapper, modalVisible && styles.show].join(' ')}>
      <div className={styles.modal} >
        <i
          onClick={() => {
            setErrorMessage(null);
            setSuccessMessage(null);
            setModalVisible(!modalVisible);
            setEmail(null);
            checkMinimumAmount();
          }}
          className="las la-times"
          style={{color:'#ffffff'}}
        />
        <div className="auth-wrapper">
          <div className="auth-inner">
            {/* <div className="wrappert"> */}
            {/* <h1>Sign in</h1> */}
            <h1>Cart Checkout</h1>
            {errorMessage && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#FAEFEF',
                  height: '40px',
                  marginTop: '3px',
                  marginBottom: '8px',
                  borderRadius: '4px',
                  padding: '5px',
                  boxShadow: '2px 2px 5px  #EBDEDF',
                  textAlign: 'center',
                }}
              >
                <div
                  style={{
                    textAlign: 'center',
                    color: '#ff0000',
                    fontSize: '14px',
                    lineHeight: '1.5',
                    fontWeight: 500,
                  }}
                >
                  {errorMessage}
                </div>
              </div>
            )}

            {successMessage && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#defee9',
                  height: '40px',
                  marginTop: '3px',
                  marginBottom: '8px',
                  borderRadius: '4px',
                  padding: '5px',
                  boxShadow: '2px 2px 5px  #EBDEDF',
                  textAlign: 'center',
                }}
              >
                <div
                  style={{
                    textAlign: 'center',
                    color: 'green',
                    fontSize: '14px',
                    lineHeight: '1.5',
                    fontWeight: 500,
                  }}
                >
                  {successMessage}
                </div>
              </div>
            )}

            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label>Redeem Type</label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  onChange={(e) => setRedeemType(e.target.value)}
                  defaultValue={'gift_card'}
                >
                  <option value="gift_card">
                    Gift Card
                  </option>
                  <option value="crypto" disabled>
                    Crypto (Future release)
                  </option>
                </select>
              </div>

              { redeemType == 'gift_card' && giftCardSelections && 
                  giftCardSelections.map((card, index) => {
                    return (
                      <ImageRadio
                        key={index}
                        src={card.creative}
                        alt={card.id}
                        value={card.id}
                        selectedValue={selectedGiftCardId}
                        onChange={handleOptionChange}
                      />
                    )
                  })
              }
              
              {redeemType == 'gift_card' && (
                <div className="mb-3">
                  <label>Email address</label>
                  <input
                    size="50"
                    name="email"
                    className="form-control inputEmail"
                    autoComplete="username"
                    type="text"
                    placeholder="Email Address"
                    ref={emailRef}
                    value={email}
                    // onChange={(e) => {
                    //   setEmail(e.target.value);
                    // }}
                  />
                </div>
              )}

              {redeemType == 'crypto' && (
                <div className="mb-3">
                  <label>Crypto address</label>
                  <input
                    size="50"
                    className="form-control"
                    autoComplete=""
                    type="text"
                    placeholder="Crypto address here"
                    onChange={(e) => {
                      setWalletAddress(e.target.value);
                    }}
                  />
                </div>
              )}

              <div className="d-grid">
              {isRedeemBtnDisabled && (
                  <Button
                    className="btn btn-primary btnSubmit"
                    type="submit"
                    variant="primary"
                    disabled
                  >
                    Redeem
                  </Button>
                )}

                {!isRedeemBtnDisabled && (
                  <Button
                    className="btn btn-primary btnSubmit"
                    type="submit"
                    variant="primary"
                  >
                    Redeem
                  </Button>
                )}

              </div>
            </form>
          </div>
        </div>
      </div>
      <Backdrop />
    </div>
  );
};
