import React, { useEffect, useState } from 'react';
import './App.css';
import Home from './components/pages/Home';
import HomeParalax from './components/pages/Home2/HomeParalax';
import HomeVideo from './components/pages/Home2/HomeVideo';
import HomeImage from './components/pages/Home2/HomeImage';
import {
  Routes,
  BrowserRouter,
  Navigate,
  Route,
} from 'react-router-dom';
import GlobalRouteChangeLogger from './state/useGlobalRouteChangeLogger'; // Import the wrapper component
import Offers from './components/pages/Offers';
import OffersOffers from './components/pages/Offers/Offers';
import SignUp from './components/pages/SignUp';
import Login from './components/Login/Login';
import Logout from './components/Login/Logout';
//import useToken from './useToken';
//import { ProvideAuth } from "./use-auth.js";
//import OfferList from './components/pages/SamplePages/Offers';
import Conversions from './components/pages/Conversions';
import ClickList from './components/pages/ClickList';
import OfferCards from './components/pages/OfferCards/OfferCards';
import useToken from './useToken';
import OffersCart from './components/pages/OffersCart';
import Forgot from './components/Login/Forgot';
import Terms from './components/pages/Company/Terms';
import Privacy from './components/pages/Company/Privacy';
import Navbar from './components/Navbar';
import NotFoundPage from './components/pages/404/NotFoundPage';
import ResetPassword from './components/Login/ResetPassword';
import Toc from './components/pages/Toc/Toc';
import Secondary from './components/pages/Secondary/Secondary';
import Parent from './components/pages/ParentDetails/Parent';
import OgNavbar from './components/OgPages/OgNavbar';
import {
  Portfolio,
  How,
  Contact,
  Contacts,
  OfferList,
  OfferListCard,
  OfferWall,
  ModalExample,
  Cart,
} from './components/pages';
import { Header, Sidebar } from './components/layouts';
//import { Footer } from './components/layouts';
import Footer from './components/layouts/Footer/Footer';
import {
  ModalForm,
  ModalVideo,
  RequestForm,
  ModalSignup,
} from './components/ui';

import { Spinner } from './components/elements';
import { useCustomState } from './state/state';
import Mock from './state/mock';
import './state/database';
import OffersNew from './components/pages/Offers/OffersNew';
import OfferRewards from './components/pages/OfferRewards/OfferRewards';
import ScrollToTop from './state/ScrollToTop';
import ConfirmEmail from './components/Register/ConfirmEmail';
import Loading from './components/ui/LoadingScreen/Loading';

import HowTo from './components/pages/Lander/HowTo';
import OfferParent  from './components/pages/Lander/OfferParent';
import { initGoogleAnalytics } from './utilities/analytics';

Mock.onAny().passThrough();

export default (props) => {

  const [cart, setCart] = useState([]);
  const [state, actions] = useCustomState();
  const { token, setToken } = useToken();

  useEffect(() => {
    actions.fetch();
  }, [actions]);

  useEffect(() => {
    initGoogleAnalytics();
    //console.log('useEffect initGoogleAnalytics');
  }, [])

  let app = state.error ? <p>Can't load page</p> : <Spinner fluid />;

  if (state.data) {
    app = (
      <>
        <BrowserRouter>
        <ScrollToTop>
          <Loading />
          {/* <Navbar cart={ cart } setCart={ setCart }/> */}
          <Sidebar data={state.data.menu} />
          <ModalForm setToken={setToken} /> {/* this is the modal login form as of 10-19-23 */ }
          <ModalSignup setToken={setToken} />
          {/* <ModalVideo /> REMOVE FROM PROJECRT FIRST CHANCE */}
          {/* <RequestForm /> */}

          <Header data={state.data.menu} cart={cart} setCart={setCart} />
          {/* <div style={{height:'2px', background:'#FFB703', margin: '0', padding: '0' }}></div>     */}
          <div className="app-content">
          <GlobalRouteChangeLogger />
            <Routes>
              {/* following entries are disabled but exist for future reference and or usage */}
              {/* <Route path="/modalexample" element={<ModalExample />} /> */}
              {/* END following entries are disabled but exist for future reference and or usage */}
              {/* <Route path="/" exact element={<Home />} /> */}
              <Route path="/" exact element={<HomeVideo pageTitle="Upmatic Home" />} />
              <Route path="/offers"
                    exact
                    element={
                      <OfferWall
                        sidebar="none"
                        layout="grid"
                        title="Earn Crypto"
                        pageTitle="Deals" />
                      }
              />

              {/* <Route path="/about" element={<About />} /> */}

              <Route path="/sign-up" element={<SignUp />} />
              <Route path="/cart" element={<Cart pageTitle="Cart" />} />
              <Route path="/offerrewards/:id" element={<OfferRewards />} />

              {/* <Route path='/login' element={<Login />} setToken={setToken}></Route> */}
              <Route path="/howto" element={<HowTo pageTitle='How To Upmatic'/>} />
              <Route path="/offer/o/:oid" element={<OfferParent pageTitle="Deal" />} />

              {process.env.REACT_APP_NODE_ENV !== 'production' && (
                <>
                  {/* <Route path="/toc" element={<Toc />} /> */}
                  <Route path="/toc" element={<Toc token={ token } />} />
                  <Route path="/offercards" element={<OfferCards />} />
                  <Route path="/offerlist" element={<OfferList />} />
                  <Route path="/homeparalax" element={<HomeParalax />} />
                  <Route path="/homeimage" element={<HomeImage />} />
                  <Route path="/home" element={<Home />} />
                  <Route path="/offerwall-grid-without-sidebar" element={<OffersNew />} />
                  {/* GRID VERSION */}
                  <Route path="/offerwall-grid-left-sidebar"
                    exact
                    element={
                      <OfferWall
                        sidebar="left"
                        layout="grid"
                        title="offerwall grid left sidebar"
                      />
                    }
                  />
                  {/* LIST VERSION */}
                  <Route path="/offerwall-list-left-sidebar"
                        exact
                        element={
                          <OfferWall
                            sidebar="left"
                            layout="list"
                            title="offerwall list left sidebar"
                            pageTitle="Deals" />
                          }
                  />
                  <Route path="/offerwall/cats/:category"
                    exact
                    element={
                      <OfferWall
                        sidebar="left"
                        layout="grid"
                        pageTitle="Offers"
                      />
                    }
                  />
                  {/* RECTANGLE STYLE CARDS */}
                  <Route path="/offerlistcard" element={<OfferListCard />} />
                  <Route path="/conversions" element={<Conversions cart={cart} setCart={setCart} />} />
                  <Route path="/offersoffers" element={<OffersOffers />} />
                  {/* FOLLOWING DOES NOT WORK FOR SOME REASON */}
                  <Route path="/clicklist" element={ClickList} />
                  <Route path="/howitworks" element={<How />} />
                  {/* DEPRECATE */}
                  <Route path="/portfolio" element={<Portfolio />} />
                  <Route path="/contact" element={<Contact />} />
                  <Route path="/contacts" element={<Contacts />} />
                  <Route path="/parent" element={<Parent />} />
                  <Route path="/ognavbar" element={<OgNavbar />} />
                </>
              )}

              { /* SECONDARY PAGES USING PARENT CHILD COMPONENT CONCEPT */}  
              <Route path="/about" exact element={<Secondary sidebar="left" layout="grid" title="About" pageTitle="About" />} />                
              <Route path="/faq" exact element={<Secondary sidebar="left" layout="grid" title="FAQ" pageTitle="FAQs" />} />                
              {/* <Route path="/howitworks" exact element={<Secondary sidebar="left" layout="grid" title="How it Works" />} /> */}                
              <Route path="/support" exact element={<Secondary sidebar="left" layout="grid" title="Support" pageTitle="Support" />} />
              <Route path="/e/confirm" exact element={<Secondary sidebar="left" layout="grid" title="Confirm" pageTitle="Newsletter Confirmation" />} />  
              <Route path="/e/confirmed" exact element={<Secondary sidebar="left" layout="grid" title="ConfirmedSuccess" pageTitle="Newsletter Confirmed" />} />              

              {/* LOGIN - LOGOUT PATHS */}
              {!state.user && (
                <Route path="/login" element={<Login setToken={setToken} />} /> 
              )}
              {!state.user && (
                <Route path="/logout" element={<Logout setToken={setToken} />} />                
              )}
              <Route path="/confirmEmail" element={<ConfirmEmail pageTitle="Confirm Email" />} />
              <Route path="/forgot" element={<Forgot pageTitle="Forgot Password" />} />
              <Route path="/terms" element={<Terms pageTitle="Terms" />} />
              <Route path="/privacy" element={<Privacy pageTitle="Privacy" />} />
              <Route path="/resetpassword" element={<ResetPassword pageTitle="Reset Password" />} />              

              {/* <Route path="/cart" element={<OffersCart cart={cart} setCart={setCart} />} />                             */}

              {/* The following should be checked if author is not used the deprecate the author and anywhere else remove any code relating to author */}
              {/* <Route path="/offerwall/user/:author"
                    exact
                    element={
                      <OfferWall
                        sidebar="left"
                        layout="grid"
                        pageTitle="Offers"
                      />
                    }
              /> */}
              {/* <Route path="/offerwall/date/:posting_date"
                    exact
                    element={
                      <OfferWall
                        sidebar="left"
                        layout="grid"
                      />
                    }
              /> */}
              {/* <Route path="/offerwall/search/:query"
                    exact
                    element={
                      <OfferWall
                        sidebar="left"
                        layout="grid"
                        pageTitle="Offers"
                      />
                    }
              />             */}

              {/* <Route path="/offerwall-grid-right-sidebar"
                        exact
                        element={
                          <OfferWall
                            sidebar="right"
                            layout="grid"
                            title="offerwall grid right sidebar"
                          />
                        }
                  />
              */}

              {/* <Route path="/offerwall-list-right-sidebar" 
                        exact
                        element={
                          <OfferWall
                            sidebar="right"
                            layout="list"
                            title="offerwall list right sidebar"
                          />
                        }
                  />
              */}

              {/* Specific route for the 404 page */}
              <Route path="/404" element={<NotFoundPage pageTitle="Page Not Found" data={ state.data.offers } />} />
              {/* Catch-all route to redirect to /404 */}
              <Route path="*" element={<Navigate to="/404" replace />} />
              
            </Routes>

          </div>
          <Footer />
          </ScrollToTop>
        </BrowserRouter>
      </>
    );
  }
  return <>{app}</>;
};

//export default App;
