import React from 'react';
import { useSearchParams } from 'react-router-dom';

const ConfirmedSuccess = () => {
  const [searchParams] = useSearchParams();
  console.log(searchParams);
  const status = searchParams.get('status');
  const error = searchParams.get('error');
  const email = searchParams.get('email');
  console.log(status);
  console.log(email);
  return (
    <>
      <div className='secondaryConfirmedSuccessContent'>
        { status === 'success' ? 
          (
            <>
            <h4>
            Thank you for confirming your subscription!
            </h4>
    
            <hr />
            <p> { email } </p>
            <p>
            We know that putting your money and effort into online rewards programs can be a gamble.  That's why upmatic.io is dedicated to building a rewards and loyalty experience where you know you're getting real value for your time.  We help you make the most of your online activity, earning and saving real money while you do it.            
            </p>
          </>
        ) :
        (
          <h1>Error: {error}</h1>
        )
        }

      </div>
    </>
  )
  }  
  export default ConfirmedSuccess