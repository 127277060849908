export default {
    color: "#ffc526",
    lightBlue: "#8ECAE6",
    blueLikeTealIsh: "#219EBC",
    NavyIsh: "#023088",
    YellowIsh: "#FFB703",
    OrangeIsh: "#FB8500",
    darkTeal: "#014d4e",
    white: "#FFFFFF",
    black: "#000000",
    greyBG: "#EEEFF1",
    pinkBtn: "#EE22E7",
    footerBgColor:"rgb(5, 145, 215)",
    purple: "#8705EF",
  };
    // color: "#ffc526"
    // color: "#EE22E7"
    // color: "#8705ef"
    //color: "#EE22E7"
    //     color: "#48BCD1",
    //     color: "#FB8500", 