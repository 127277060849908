import React from 'react';
import './Login.css';
import {
  Header,

} from "../widgets";
import { useCustomState } from "../../state/state";


export default function Logout() {
  const state = useCustomState()[0];

  return(
    <>
    {/* <Header img={state.data.header_bgs.about}>Logged Out</Header> */}
      <div className="emptyDivHeight">
        Logged Out, TODO put offers in here or something
      </div>
      </>
  )
}

Logout.prototype = {
   // setToken: PropTypes.func.isRequired
}