// js truncate text
export function truncateText(text, maxLength) {
    if (text != null && text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    } else {
      return text;
    }
  }
  
  // Sanitize input to avoid XSS attacks
  export function sanitizeInput(input) {
      const map = {
          '&': '&amp;',
          '<': '&lt;',
          '>': '&gt;',
          '"': '&quot;',
          "'": '&#x27;',
          "/": '&#x2F;',
      };
      const reg = /[&<>"'/]/ig;
      return input.replace(reg, (match) => (map[match]));
  };